.container {
  padding-inline: 80px;
  padding-block: var(--heroSectionMarginTop) 80px;
}

.container > form {
  width: 45%;
  margin: 96px auto;
}

.header > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.header > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 8px;
}

.formBody {
  margin: 40px 0;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    padding-block: var(--heroSectionMarginTopMobile) 80px;
  }

  .container > form {
    width: 100%;
    margin: 40px auto;
  }

  .header > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .header > p {
    font-size: 14px;
    line-height: 23px;
    width: 70%;
    margin: 8px auto 0 auto;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    padding-block: var(--heroSectionMarginTopMobile) 80px;
  }

  .container > form {
    width: 100%;
    margin: 40px auto;
  }

  .header > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .header > p {
    font-size: 14px;
    line-height: 23px;
    width: 90%;
    margin: 8px auto 0 auto;
  }
}
