.container input {
    display: none;
}

.container {
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 20px;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}

.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 1.3em;
    width: 1.3em;
    background-color: #2196F300;
    border-radius: 0.25em;
    transition: all 0.25s;
}

.container input:checked~.checkmark {
    background-color: var(--purple);
}

.checkmark:after {
    content: "";
    position: absolute;
    transform: rotate(0deg);
    border: 0.1em solid var(--purple);
    left: 0;
    top: 0;
    width: 0.95em;
    height: 0.95em;
    border-radius: 0.25em;
    transition: all 0.25s, border-width 0.1s;
}

.container input:checked~.checkmark:after {
    left: 0.45em;
    top: 0.25em;
    width: 0.25em;
    height: 0.5em;
    border-color: #fff0 white white #fff0;
    border-width: 0 0.15em 0.15em 0;
    border-radius: 0em;
    transform: rotate(45deg);
}