.container {
  background-color: var(--yellow);
  position: relative;
  overflow: hidden;
}

.header {
  text-align: center;
  padding: 80px 185px;
}

.header > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple3);
}

.header > h2:nth-child(2) {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin: 8px 0;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
}

.header > p:nth-child(3) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--grey5);
}

.header > button {
  margin-top: 24px;
  max-width: 169px;
  margin-inline: auto;
}

.missionContainer {
  padding-bottom: 120px;
}

.imageSection,
.mission,
.missionContainer {
  display: flex;
  align-items: stretch;
  /* overflow-x: auto; */
  gap: 12px;
}

.missionContainer > div {
  flex-shrink: 0;
  flex-grow: 0;
}

.mission > div:nth-child(1) > img {
  width: 60px;
  height: 60px;
}

.mission > div:nth-child(1) {
  flex-shrink: 0;
  flex-grow: 0;
  width: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  gap: 24px;
}

.mission > div:nth-child(1) > span {
  font-family: Handlee;
  font-size: 32px;
  font-weight: 400;
  line-height: 61px;
  text-align: center;
  transform: rotate(90deg);
  display: block;
}

.mission:nth-child(3n -2) > div:nth-child(1) {
  border: 1px solid var(--purple);
  color: var(--purple);
  background-color: var(--veryVeryLightPurple);
}

.mission:nth-child(3n + 2) > div:nth-child(1) {
  border: 1px solid var(--timerGreen);
  color: var(--timerGreen);
  background-color: var(--scheduleRightCtaGreen);
}

.mission:nth-child(3n) > div:nth-child(1) {
  border: 1px solid var(--progressBarIndicatorYellow);
  color: var(--progressBarIndicatorYellow);
  background-color: var(--yellow);
}

.imageSection > img:nth-child(1) {
  width: 300px;
  height: 280px;
  border-radius: 10px;
}

.imageSection > img:nth-child(2) {
  width: 105px;
  height: 280px;
  border-radius: 10px;
}

.missionContainer {
  animation: loop 30s linear infinite;
}

@keyframes loop {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

@media screen and (max-width: 767px) {
  .header {
    text-align: center;
    padding: 40px 16px;
  }

  .header > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .header > p:nth-child(3) {
    font-size: 14px;
    line-height: 23px;
  }

  .missionContainer {
    padding-bottom: 120px;
  }

  .header > h2 > span > svg {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    text-align: center;
    padding: 40px 16px;
  }

  .header > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .header > p:nth-child(3) {
    font-size: 14px;
    line-height: 23px;
  }

  .missionContainer {
    padding-bottom: 120px;
  }

  .header > h2 > span > svg {
    display: none;
  }
}
