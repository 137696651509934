.container {
  width: 100%;
}

.container > p:nth-child(1) {
  color: var(--green);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.65px;
}

.container > h4 {
  color: var(--grey);
  font-family: Archivo;
  font-size: 49px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
}

.container > p:nth-child(3) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > p {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
  }

  .container > h4 {
    font-size: 31px;
    line-height: 40px;
  }
}

@media screen and (max-width: 767px) {
  .container > p {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
  }

  .container > h4 {
    font-size: 31px;
    line-height: 40px;
  }
}
