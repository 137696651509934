.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px 0px 0px 0px;
  width: 720px;
  height: 370px;
}

.topSection {
  display: flex;
  align-items: stretch;
  flex: 1;
}

.topSection > div:nth-child(1) {
  background-color: var(--greyWhite);
  flex: 55%;
  padding: 16px 0px 16px 16px;
}

.topSection > div:nth-child(1) > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  padding: 1rem;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
  transition: all 0.3s ease-in-out;
  border-radius: 5px 0px 0px 5px;
}

.topSection > div:nth-child(1) > div > svg {
  flex-shrink: 0;
}

.topSection > div:nth-child(2) {
  padding: 16px;
}

.topSection > div:nth-child(2) > a {
  padding: 1rem;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
  transition: all 0.3s ease-in-out;
  border-radius: 5px 0px 0px 5px;
  cursor: pointer;
  text-decoration: none;
}

.topSection > div:nth-child(1) > div:hover {
  background-color: var(--white);
  color: var(--purple);
}

.topSection > div:nth-child(2) > div:hover {
  color: var(--purple);
}

.topSection > div:nth-child(2) {
  flex: 45%;
}

.bottomSection {
  flex-basis: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  line-height: 27px;
  color: var(--white);
  background: url("../../Assets/Images/headerDropdownBottomBg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.noCourses {
  padding: 2rem 0;
  font-family: Archivo;
  font-size: 14px;
  font-weight: 600;
  line-height: 27px;
  color: var(--grey3);
  text-align: center;
}
