.container {
  display: flex;
  align-items: stretch;
  background-color: var(--yellow);
}

.textSection {
  flex-basis: 60%;
  padding: 80px;
}

.textSection > h6 {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 1rem;
  color: var(--purple);
}

.textSection > h2 {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 400;
  line-height: 55px;
  text-align: left;
  margin-bottom: 1.5rem;
}

.textSection > h2 > span {
  font-weight: 600;
}

.estimates {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.estimates > div > h3 {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  color: var(--purple);
}

.estimates > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}

.textSection > p:nth-child(4) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey3);
}

.imagesSection {
  height: 602px;
  width: 602px;
}

.imagesSection > img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .container {
    flex-flow: column;
  }

  .textSection {
    flex-basis: 100%;
    padding: 40px 16px;
  }

  .textSection > h6 {
    font-size: 10px;
    line-height: 18px;
    text-align: left;
  }

  .textSection > h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 1.5rem;
  }

  .textSection > h2 > span {
    font-weight: 600;
    color: var(--purple);
  }

  .estimates {
    margin-bottom: 1rem;
    gap: 1.5rem;
  }

  .estimates > div > h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .estimates > div > p {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > p:nth-child(4) {
    font-size: 12px;
    line-height: 23px;
  }

  .imagesSection {
    width: 100%;
    height: 100%;
    padding: 40px 16px;
  }

  .imagesSection > img {
    height: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-flow: column;
  }

  .textSection {
    flex-basis: 100%;
    padding: 40px 16px;
  }

  .textSection > h6 {
    font-size: 10px;
    line-height: 18px;
    text-align: left;
  }

  .textSection > h2 {
    font-size: 25px;
    line-height: 35px;
    margin-bottom: 1.5rem;
  }

  .textSection > h2 > span {
    font-weight: 600;
    color: var(--purple);
  }

  .estimates {
    margin-bottom: 1rem;
    gap: 1.5rem;
  }

  .estimates > div > h3 {
    font-size: 20px;
    line-height: 30px;
  }

  .estimates > div > p {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > p:nth-child(4) {
    font-size: 12px;
    line-height: 23px;
  }

  .imagesSection {
    width: 100%;
    height: 100%;
    padding: 40px 16px;
  }

  .imagesSection > img {
    height: 100%;
    width: 100%;
  }
}
