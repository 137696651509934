.container {
  min-height: 100vh;
  padding: 0px 80px;
}

.header {
  padding: 0px 24px;
}

.body {
  padding: 80px 0;
  display: flex;
  align-items: flex-start;
  gap: 227px;
}

.caption {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: var(--green2);
  margin-bottom: 8px;
}

.headerText {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  color: var(--black);
}

.description {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--black);
  margin-bottom: 8px;
}

.imagesSection {
  width: 50%;
  max-height: 729.33px;
}

.textSection {
  width: 50%;
}

.imagesSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.progressBar {
  margin-top: 40px;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 40px;
  margin-top: 40px;
}

.buttonSection > button {
  max-width: 135px;
}

.bodyContainer > div {
  margin-top: 40px;
}

.optionContainer > div {
  display: flex;
  align-items: center;
  padding: 20px 1rem;
  gap: 2.5rem;
  background-color: var(--bodyBackgroundColor);
  border: 1px solid var(--sectionBorder);
  margin-bottom: 8px;
}

@media screen and (max-width: 767px) {
  .container {
    min-height: 100vh;
    padding: 0px 16px;
  }

  .header {
    padding: 0px 24px;
  }

  .body {
    padding: 40px 0;
    flex-direction: column;
    gap: 23px;
    position: relative;
    min-height: 80vh;
  }

  .caption {
    display: none;
  }

  .headerText {
    font-size: 25px;
    line-height: 35px;
  }

  .description {
    font-size: 14px;
    line-height: 23px;
  }

  .imagesSection {
    width: 100%;
    height: 228.67px;
    display: none;
  }

  .textSection {
    width: 100%;
  }

  .imagesSection > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .progressBar {
    margin-top: 40px;
  }

  .buttonSection {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 200px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .buttonSection > button {
    max-width: 50%;
  }

  .bodyContainer > div {
    margin-top: 40px;
  }

  .optionContainer > div {
    padding: 1rem;
    gap: 2rem;
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    min-height: 100vh;
    padding: 0px 16px;
  }

  .header {
    padding: 0px 24px;
  }

  .body {
    padding: 40px 0;
    flex-direction: column;
    gap: 23px;
    position: relative;
    min-height: 80vh;
  }

  .caption {
    display: none;
  }

  .headerText {
    font-size: 25px;
    line-height: 35px;
  }

  .description {
    font-size: 14px;
    line-height: 23px;
  }

  .imagesSection {
    width: 100%;
    height: 228.67px;
    display: none;
  }

  .textSection {
    width: 100%;
  }

  .imagesSection > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .progressBar {
    margin-top: 40px;
  }

  .buttonSection {
    display: flex;
    align-items: center;
    gap: 40px;
    margin-top: 200px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .buttonSection > button {
    max-width: 50%;
  }

  .bodyContainer > div {
    margin-top: 40px;
  }

  .optionContainer > div {
    padding: 1rem;
    gap: 2rem;
    font-size: 14px;
    line-height: 23px;
  }
}
