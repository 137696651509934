.container {
  position: relative;
}

.header {
  position: fixed;
  top: 0;
  height: 98px;
  z-index: 2;
  width: 100%;
}

.body {
  min-height: calc(100vh);
  overflow-x: hidden;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    height: 49px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    height: 49px;
  }
}
