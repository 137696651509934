.container {
  padding: 120px 196px;
  background-color: var(--veryVeryLightPurple);
  position: relative;
}

.header {
  text-align: center;
}

.header > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--green);
}

.header > h2:nth-child(2) {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin: 8px 0;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
}

.header > p:nth-child(3),
.noCourses {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--grey5);
}

.schoolsSection {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
  margin-top: 57px;
}

.schoolsSection > div {
  flex-basis: calc(50% - 24px);
}

.container > p:nth-child(3) {
  font-family: Handlee;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--black);
  width: 209.79px;
  gap: 0px;
  transform: rotate(-14.84deg);
  margin-top: 73px;
  margin-bottom: 61px;
  margin-inline: auto;
  position: relative;
}

.container > p:nth-child(3) > svg {
  position: absolute;
  right: -20%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.quizSection {
  padding: 2rem;
  width: 535px;
  margin: auto;
  background-color: var(--purple);
  text-align: center;
  border-radius: 20px;
}

.quizSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.buttonSection > button {
  max-width: 180px;
}

.container > svg {
  position: absolute;
}

.container > svg:nth-child(5) {
  top: 0;
  left: 0;
}

.container > svg:nth-child(6) {
  top: 53px;
  right: 0;
}

.container > svg:nth-child(7) {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.container > svg:nth-child(8) {
  bottom: 79.3px;
  left: 0;
}

.container > svg:nth-child(9) {
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 16px;
    position: relative;
  }

  .header > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    width: 151.79px;
    height: 33.55px;
    display: none;
  }

  .header > h2 > svg {
    display: none;
  }

  .header > p:nth-child(3) {
    font-size: 14px;
    line-height: 23px;
  }

  .schoolsSection {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  .schoolsSection > div {
    flex-basis: 100%;
  }

  .container > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;
    width: 126.41px;
    margin-top: 73px;
  }

  .container > p:nth-child(3) > svg {
    right: -50%;
    top: 0;
    bottom: 0;
  }

  .quizSection {
    padding: 1rem 2rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .buttonSection {
    flex-direction: column;
    gap: 24px;
  }

  .container > svg {
    z-index: -1;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
    position: relative;
  }

  .header > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }
  .header > h2 > svg {
    display: none;
  }

  .header > h2 > span > svg {
    width: 151.79px;
    height: 33.55px;
    display: none;
  }

  .header > p:nth-child(3) {
    font-size: 14px;
    line-height: 23px;
  }

  .schoolsSection {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 24px;
  }

  .schoolsSection > div {
    flex-basis: 100%;
  }

  .container > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;
    width: 126.41px;
    margin-top: 73px;
  }

  .container > p:nth-child(3) > svg {
    right: -50%;
    top: 0;
    bottom: 0;
    display: none;
  }

  .quizSection {
    padding: 1rem 2rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .buttonSection {
    flex-direction: column;
    gap: 24px;
  }

  .container > svg {
    z-index: -1;
  }
}
