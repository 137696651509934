.container {
  display: flex;
  align-items: center;
  padding: 80px;
  gap: 80px;
}

.textSection {
  flex-basis: 30%;
}

.textSection > h6 {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  margin-bottom: 1rem;
}

.textSection > h2 {
  font-family: Archivo;
  font-size: 39px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1rem;
}

.textSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 2.5rem;
}

.textSection > button {
  max-width: 170px;
  margin-bottom: 80px;
}

.decoration {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardsSection {
  flex: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  flex-wrap: wrap;
}

.cardsSection > div {
  flex-basis: calc(50% - 24px);
  padding: 24px;
  border-radius: 10px;
  background-color: var(--yellow);
}

.cardsSection > div > img {
  width: 40px;
  height: 40px;
}

.cardsSection > div > h4 {
  margin-block: 0.5rem;
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  color: var(--grey);
}

.cardsSection > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

@media screen and (max-width: 767px) {
  .container {
    flex-direction: column;
    padding: 40px 1rem;
    gap: 24px;
  }

  .textSection {
    flex-basis: 100%;
  }

  .textSection > h6 {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .textSection > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .textSection > p {
    font-size: 12px;
    line-height: 23px;
    margin-bottom: 2.5rem;
  }

  .textSection > button {
    max-width: 170px;
    margin-bottom: 0px;
  }

  .decoration {
    display: none;
  }

  .cardsSection {
    flex: 100%;
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  .cardsSection > div {
    flex-basis: 100%;
    width: 100%;
    padding: 16px;
  }

  .cardsSection > div > h4 {
    margin-block: 0.5rem;
    font-size: 16px;
    line-height: 25px;
  }

  .cardsSection > div > p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    flex-direction: column;
    padding: 40px 1rem;
    gap: 24px;
  }

  .textSection {
    flex-basis: 100%;
  }

  .textSection > h6 {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .textSection > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .textSection > p {
    font-size: 12px;
    line-height: 23px;
    margin-bottom: 2.5rem;
  }

  .textSection > button {
    max-width: 170px;
    margin-bottom: 0px;
  }

  .decoration {
    display: none;
  }

  .cardsSection {
    flex: 100%;
    width: 100%;
    flex-direction: column;
    gap: 12px;
  }

  .cardsSection > div {
    flex-basis: 100%;
    width: 100%;
    padding: 16px;
  }

  .cardsSection > div > h4 {
    margin-block: 0.5rem;
    font-size: 16px;
    line-height: 25px;
  }

  .cardsSection > div > p {
    font-size: 14px;
    line-height: 23px;
  }
}
