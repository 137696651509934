/* .container {
  height: 100%;
  padding: 48px 58px;
  overflow-y: auto;
}

.cancel {
  text-align: right;
  margin-bottom: 40px;
}

.navItemsContainer {
  margin-left: auto;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}

.navItemOtherOptionsInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.otherOptionsContainer {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin: 1rem auto 0 auto;
}

.otherOptionsContainerInner {
  padding: 0.5rem;
  max-height: 308px;
  user-select: none;
  display: block;
  margin: auto;
  box-sizing: border-box;
}

.otherOptionsContainerInner > a,
.navItems {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
  display: block;
  padding: 10px 0;
}

.activeLink {
  color: var(--purple);
}

.buttonSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;
}

.buttonSection > div:nth-child(1),
.buttonSection > div:nth-child(2) {
  width: 122px;
}

.buttonSection > div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 6px;
} */

.container {
  height: 100%;
  padding: 48px 58px;
  overflow-y: auto;
}

.cancel {
  text-align: right;
  margin-bottom: 40px;
}

.navItemsContainer {
  margin-left: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
}

.navItemOtherOptionsInner {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: relative;
  cursor: pointer;
}

.otherOptionsContainer {
  width: 100%;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  margin: 1rem auto 0 auto;
}

.otherOptionsContainerInner {
  padding: 0.5rem;
  max-height: 308px;
  user-select: none;
  display: block;
  margin: auto;
  box-sizing: border-box;
}

.otherOptionsContainerInner > a,
.navItems {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
  display: block;
  padding: 10px 0;
}

.activeLink {
  color: var(--purple);
}

.buttonSection {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;
}

.buttonSection > div:nth-child(1),
.buttonSection > div:nth-child(2) {
  width: 122px;
}

.buttonSection > div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 6px;
}
