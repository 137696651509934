.brandSection {
  background-color: var(--yellow);
  padding: 40px;
}

.brandSection > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: var(--grey);
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}
