.container {
  padding: 64px 73px 64px 73px;
  display: flex;
  align-items: flex-start;
  gap: 4rem;
}

.textSection {
  flex-basis: 60%;
}

.textSection > h4 {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.textSection > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1rem;
}

.textSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}

.objectives {
  padding: 40px 24px;
  gap: 8px;
  border-radius: 10px;
  border: 2px solid var(--purple);
  flex-basis: 40%;
  background-color: var(--yellow);
}

.objectives > h5 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: var(--grey);
}

.objectives > ul {
  list-style-type: none;
  margin-left: -40px;
  margin-top: 12px;
}

.objectives > ul > li {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}

.objectives > ul > li > span:nth-child(1) {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid var(--grey);
  display: flex;
  align-items: center;
  justify-content: center;
}

.objectives > ul > li > span > svg {
  width: 24px;
  height: 24px;
}

.objectives > button {
  margin-top: 2rem;
  max-width: 142px;
}

.objectives > p:last-child {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey3);
  margin-top: 12px;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .textSection {
    flex-basis: 60%;
  }

  .textSection > h4 {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
    color: var(--grey);
  }

  .textSection > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .objectives {
    padding: 16px;
    flex-basis: 100%;
  }

  .objectives > h5 {
    font-size: 18px;
    line-height: 27px;
  }

  .objectives > ul {
    margin-top: 24px;
  }

  .objectives > ul > li {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 14px;
    line-height: 23px;
  }

  .objectives > ul > li > span:nth-child(1) {
    width: 24px;
    height: 24px;
  }

  .objectives > ul > li > span > svg {
    width: 16px;
    height: 16px;
  }

  .objectives > button {
    max-width: 142px;
  }

  .objectives > p:last-child {
    font-size: 12px;
    line-height: 23px;
    margin-top: 24px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .textSection {
    flex-basis: 60%;
  }

  .textSection > h4 {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
    color: var(--grey);
  }

  .textSection > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .objectives {
    padding: 16px;
    flex-basis: 100%;
  }

  .objectives > h5 {
    font-size: 18px;
    line-height: 27px;
  }

  .objectives > ul {
    margin-top: 24px;
  }

  .objectives > ul > li {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 14px;
    line-height: 23px;
  }

  .objectives > ul > li > span:nth-child(1) {
    width: 24px;
    height: 24px;
  }

  .objectives > ul > li > span > svg {
    width: 16px;
    height: 16px;
  }

  .objectives > button {
    max-width: 142px;
  }

  .objectives > p:last-child {
    font-size: 12px;
    line-height: 23px;
    margin-top: 24px;
  }
}
