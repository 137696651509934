@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.container {
  margin-bottom: 24px;
}

.container > label {
  color: var(--grey);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.container > span:nth-child(2) {
  color: var(--red);
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}

.container > input {
  display: block;
  width: 100%;
  padding: 18px 15px;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 5px 0;
  border-radius: 5px;
  animation: appear 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: transparent;
}

.container > input:focus {
  outline: 1px solid rgba(102, 78, 254, 1);
}

/*  */
.valid {
  border: 1px solid var(--grey);
  color: var(--grey);
}

.invalid {
  border: 1px solid var(--red);
  color: var(--red);
}

.container > input::placeholder {
  color: var(--grey3);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
}

.errorMessage {
  color: var(--red);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  animation: appear 0.2s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.tip {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > input {
    padding: 12px;
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
    border-radius: 5px;
  }

  .container > label {
    font-size: 16px;
    line-height: 25px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }
}

@media screen and (max-width: 767px) and (max-width: 1023px) {
  .container > input {
    padding: 12px;
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
    border-radius: 5px;
  }

  .container > label {
    font-size: 16px;
    line-height: 25px;
  }

  .errorMessage {
    font-size: 10px;
    line-height: 18px;
  }
}
