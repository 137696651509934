@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes borderEffect {
  0% {
    border-bottom: 2px solid #f2f3f8;
  }

  100% {
    border-bottom: 3px solid var(--purple);
  }
}

.container {
  display: flex;
  align-items: center;
  gap: 3rem;
  padding: 0 80px;
  height: 98px;
  transition: all 0.3s ease-in-out;
}

.container > div {
  height: 100%;
}

.logoSection {
  width: 53px;
  height: 50px;
  flex-shrink: 0;
}

.logoSection > img {
  width: 100%;
  height: 100%;
}

.navItemsAndButtons {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 34px;
}

.navItemOtherOptions {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.otherOptionsContainer {
  position: absolute;
  left: 30px;
  top: 50px;
  border-radius: 0.5rem;
  padding: 0;
  background: rgb(255 255 255);
  /* width: 258px; */
  /* max-height: 308px; */
  user-select: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  animation: appear 0.3s ease-in-out;
  z-index: 1;

  max-width: 720px;
  min-width: 300px;
  min-height: 100px;
  max-height: 370px;
}

.otherOptionsContainer > a,
.navItems {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration: none;
  display: block;
  padding: 10px;
}

.otherOptionsContainer > a {
  padding: 0.5rem 1rem;
}

.otherOptionsContainer > a:hover {
  background-color: #e0dddd;
}

.navItems {
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeLink {
  border-bottom: 3px solid var(--purple);
  animation: borderEffect 0.3s ease-in-out;
}

.buttonSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonSection > div:nth-child(1),
.buttonSection > div:nth-child(2) {
  width: 122px;
}

.buttonSection > div:nth-child(3) {
  display: flex;
  align-items: center;
  gap: 6px;
}

.sideNavIndicator {
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.sideNav {
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 20;
  background-color: var(--white);
  overflow: hidden;
  width: 0;
  transition: 0.3s;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 0 1rem;
    height: 49px;
  }

  .logoSection {
    height: 35px;
    width: 35px;
  }

  .navItemsAndButtons,
  .buttonSection {
    display: none;
  }

  .sideNavIndicator {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 1rem;
    height: 49px;
  }

  .logoSection {
    height: 35px;
    width: 35px;
  }

  .navItemsAndButtons,
  .buttonSection {
    display: none;
  }

  .sideNavIndicator {
    display: flex;
  }
}
