.container {
  padding: var(--heroSectionMarginTop) 80px 40px 80px;
}

.sectionNav {
  margin-top: 80px;
}

.faqs {
  width: 80%;
  margin: auto;
  padding: 80px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: var(--heroSectionMarginTopMobile) 16px 2rem 16px;
  }

  .sectionNav {
    margin-top: 40px;
  }

  .faqs {
    width: 100%;
    padding: 16px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 2rem 16px;
    padding: var(--heroSectionMarginTopMobile) 16px 2rem 16px;
  }

  .sectionNav {
    margin-top: 40px;
  }

  .faqs {
    width: 100%;
    padding: 16px;
  }
}
