.container {
  padding: 80px;
  position: relative;
  background-color: var(--white);
}

.header {
  text-align: center;
}

.header > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple);
  margin-bottom: 0.5rem;
}

.header > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.body {
  display: flex;
  align-items: stretch;
  gap: 4rem;
  margin-top: 56px;
}

.body > div {
  flex-basis: 50%;
}

.body > div:nth-child(1) > p {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1.5rem;
}

.body > div:nth-child(1) > img {
  height: 462.64px;
  width: 100%;
}

.body > div:nth-child(2) {
  padding: 40px 24px;
  border-radius: 10px;
  border: 2px solid var(--purple);
  background-color: var(--yellow);
}

.body > div:nth-child(2) > h2 {
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: Archivo;
  font-size: 25px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.body > div:nth-child(2) > p:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}

.stats {
  margin-top: 24px;
}

.stat {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
}

.stat > span:nth-child(1),
.stat > span:nth-child(3) {
  white-space: nowrap;
  flex-basis: 146px;
  min-width: 146px;
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
}

.stat > div:nth-child(2) > div {
  height: 1rem;
}

.body > div:nth-child(2) > p:nth-child(4) {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: var(--purple);
  margin: 24px 0;
}

.body > div:nth-child(2) > p:nth-child(5) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 1rem;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .body {
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 24px;
  }

  .body > div {
    flex-basis: 100%;
  }

  .body > div:nth-child(1) > p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 1.5rem;
  }

  .body > div:nth-child(1) > img {
    height: 246px;
    width: 100%;
  }

  .body > div:nth-child(2) {
    padding: 40px 16px;
  }

  .body > div:nth-child(2) > h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0.5rem;
  }

  .body > div:nth-child(2) > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .stats {
    margin-top: 24px;
  }

  .stat {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
  }

  .stat > span:nth-child(1),
  .stat > span:nth-child(3) {
    white-space: nowrap;
    flex-basis: 120px;
    min-width: 120px;
    font-size: 16px;
    line-height: 25px;
  }

  .stat > div:nth-child(2) > div {
    height: 0.8rem;
  }

  .body > div:nth-child(2) > p:nth-child(4) {
    font-size: 16px;
    line-height: 25px;
  }

  .body > div:nth-child(2) > p:nth-child(5) {
    font-size: 12px;
    line-height: 23px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 1rem;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
    margin-bottom: 0.5rem;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .body {
    flex-direction: column;
    gap: 1.5rem;
    margin-top: 24px;
  }

  .body > div {
    flex-basis: 100%;
  }

  .body > div:nth-child(1) > p {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 1.5rem;
  }

  .body > div:nth-child(1) > img {
    height: 246px;
    width: 100%;
  }

  .body > div:nth-child(2) {
    padding: 40px 16px;
  }

  .body > div:nth-child(2) > h2 {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 0.5rem;
  }

  .body > div:nth-child(2) > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .stats {
    margin-top: 24px;
  }

  .stat {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 24px;
  }

  .stat > span:nth-child(1),
  .stat > span:nth-child(3) {
    white-space: nowrap;
    flex-basis: 120px;
    min-width: 120px;
    font-size: 16px;
    line-height: 25px;
  }

  .stat > div:nth-child(2) > div {
    height: 0.8rem;
  }

  .body > div:nth-child(2) > p:nth-child(4) {
    font-size: 16px;
    line-height: 25px;
  }

  .body > div:nth-child(2) > p:nth-child(5) {
    font-size: 12px;
    line-height: 23px;
  }
}
