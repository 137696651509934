.container {
  padding: 120px 196px;
  background-color: var(--white);
  position: relative;
}

.header {
  text-align: center;
}

.header > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple3);
}

.header > h2:nth-child(2) {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin: 8px 0;
}

.header > p:nth-child(3) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--grey5);
}

.faqContainer {
  margin: auto;
  width: 700px;
}

.faqContainer > div {
  margin-bottom: 1rem;
}

.container > p:nth-child(3) {
  font-family: Handlee;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--black);
  width: 209.79px;
  gap: 0px;
  transform: rotate(-14.84deg);
  margin-top: 73px;
  margin-bottom: 61px;
  margin-inline: auto;
  position: relative;
}

.container > p:nth-child(3) > svg {
  position: absolute;
  right: -20%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.quizSection {
  padding: 2rem;
  width: 535px;
  margin: auto;
  background-color: var(--purple);
  text-align: center;
  border-radius: 20px;
}

.quizSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.buttonSection > button {
  max-width: 180px;
}

.container > svg {
  position: absolute;
}

.container > svg:nth-child(5) {
  top: 0;
  left: 0;
}

.container > svg:nth-child(6) {
  top: 53px;
  right: 0;
}

.container > svg:nth-child(7) {
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}

.container > svg:nth-child(8) {
  bottom: 0;
  right: 0;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 16px;
  }

  .header > p:nth-child(1) {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-family: Archivo;
    font-size: 31px;
    line-height: 40px;
  }

  .container > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;

    width: 81.79px;
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .container > p:nth-child(3) > svg {
    position: absolute;
    right: -80%;
    top: 40px;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
    border-radius: 16px;
    margin-top: 4rem;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > svg {
    display: none;
  }

  .faqContainer {
    width: 100%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
  }

  .header > p:nth-child(1) {
    font-family: Archivo;
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-family: Archivo;
    font-size: 31px;
    line-height: 40px;
  }

  .container > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;

    width: 81.79px;
    margin-top: 34px;
    margin-bottom: 34px;
  }

  .container > p:nth-child(3) > svg {
    position: absolute;
    right: -50%;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
    border-radius: 16px;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > svg {
    position: absolute;
    display: none;
  }

  .faqContainer {
    width: 100%;
  }
}
