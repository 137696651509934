.container {
  background-color: var(--green);
  position: relative;
}

.header {
  text-align: center;
  padding: 80px 196px;
}

.header > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--white);
}

.header > h2:nth-child(2) {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin: 8px 0;
  color: var(--white);
}

.header > h2:nth-child(2) > span {
  position: relative;
}

.header > h2:nth-child(2) > span > svg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 112px;
  height: 47px;
}

.header > p:nth-child(3) {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  color: var(--getStartedBorder);
}

.header > p:nth-child(3) > span {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: white;
  /* color: var(--getStartedBorder); */
}

.header > button {
  margin-top: 50px;
  max-width: 181px;
  margin-inline: auto;
}

.content {
  padding: 80px;
  border-radius: 70px 70px 0px 0px;
  background-color: var(--scheduleRightCtaGreen);
}

.contentHeader {
  text-align: center;
}

.contentHeader > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--green);
}

.contentHeader > h2:nth-child(2) {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin: 8px 0;
}

.contentHeader > h2:nth-child(2) > span {
  position: relative;
}

.contentHeader > h2:nth-child(2) > span > svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 133px;
  height: 45px;
}

.contentVoices {
  margin-top: 56px;
  display: flex;
  align-items: stretch;
  gap: 24px;
}

.voice {
  padding: 1.5rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  background-color: var(--white);
}

.voice > p:nth-child(1) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
  margin-bottom: 8px;
}

.voice > p:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
}

.content > p:nth-child(3) {
  font-family: Handlee;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--black);
  width: 209.79px;
  gap: 0px;
  transform: rotate(-14.84deg);
  margin-top: 103px;
  margin-bottom: 61px;
  margin-inline: auto;
  position: relative;
}

.content > p:nth-child(3) > svg {
  position: absolute;
  right: -20%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.quizSection {
  padding: 2rem;
  width: 535px;
  margin: auto;
  background-color: var(--green);
  text-align: center;
  border-radius: 20px;
}

.quizSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.buttonSection > button {
  max-width: 180px;
}

@media screen and (max-width: 767px) {
  .header {
    padding: 40px 16px;
  }

  .header > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-family: Archivo;
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2:nth-child(2) > span {
    position: relative;
  }

  .header > h2:nth-child(2) > span > svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 76.63px;
    height: 26.27px;
  }

  .header > p:nth-child(3) {
    font-size: 14px;
    line-height: 23px;
  }

  .header > p:nth-child(3) > span {
    font-size: 14px;
    line-height: 23px;
  }

  .header > button {
    margin-top: 32px;
  }

  .content {
    padding: 40px 1rem;
    border-radius: 40px 40px 0px 0px;
  }

  .contentHeader > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .contentHeader > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .contentHeader > h2:nth-child(2) > span {
    position: relative;
  }

  .contentHeader > h2:nth-child(2) > span > svg {
    left: 0;
    top: 0;
    width: 87px;
    height: 26px;
  }

  .contentVoices {
    margin-top: 24px;
    display: flex;
    align-items: stretch;
    gap: 24px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .contentVoices::-webkit-scrollbar {
    display: none;
  }

  .voice {
    background-color: var(--white);
    flex-shrink: 0;
    flex-grow: 0;
    height: 194px;
    max-width: 90%;
  }

  .voice > p:nth-child(1) {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .voice > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .content > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;
    width: 118px;
    gap: 0px;
    margin-top: 53px;
    margin-bottom: 32px;
  }

  .content > p:nth-child(3) > svg {
    right: -50%;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .header {
    padding: 40px 16px;
  }

  .header > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .header > h2:nth-child(2) {
    font-family: Archivo;
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2:nth-child(2) > span {
    position: relative;
  }

  .header > h2:nth-child(2) > span > svg {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 76.63px;
    height: 26.27px;
  }

  .header > p:nth-child(3) {
    font-size: 18px;
    font-weight: 500;
  }

  .header > p:nth-child(3) > span {
    font-size: 14px;
    line-height: 23px;
  }

  .header > button {
    margin-top: 32px;
  }

  .content {
    padding: 40px 1rem;
    border-radius: 40px 40px 0px 0px;
  }

  .contentHeader > p:nth-child(1) {
    font-size: 10px;
    line-height: 18px;
  }

  .contentHeader > h2:nth-child(2) {
    font-size: 31px;
    line-height: 40px;
  }

  .contentHeader > h2:nth-child(2) > span {
    position: relative;
  }

  .contentHeader > h2:nth-child(2) > span > svg {
    left: 0;
    top: 0;
    width: 87px;
    height: 26px;
  }

  .contentVoices {
    margin-top: 24px;
    display: flex;
    align-items: stretch;
    gap: 24px;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .contentVoices::-webkit-scrollbar {
    display: none;
  }

  .voice {
    background-color: var(--white);
    flex-shrink: 0;
    flex-grow: 0;
    height: 194px;
    max-width: 90%;
  }

  .voice > p:nth-child(1) {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .voice > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .content > p:nth-child(3) {
    font-size: 16px;
    line-height: 22px;
    width: 118px;
    gap: 0px;
    margin-top: 53px;
    margin-bottom: 32px;
  }

  .content > p:nth-child(3) > svg {
    right: -50%;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }
}
