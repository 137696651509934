.container {
  padding: 80px 48px;
  background: var(--white);
  margin: 0px 24px;
  background: var(--veryVeryLightPurple);
}

.daysCounter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* gap: 80px;
  margin: 40px; */
}

.daysCounter > div {
  width: 156px;
  height: 180px;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
}

.daysCounter > div > div:nth-child(1) {
  height: 75%;
  color: var(--grey);
  font-family: Archivo;
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 76px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--greyWhite);
  border-radius: 10px 10px 0 0;
}

.daysCounter > div > div:nth-child(2) {
  background: var(--grey);
  height: 25%;
  display: flex;
  align-items: center;
  justify-self: center;
  text-align: center;
  color: var(--white);
  text-align: center;
  font-family: Archivo;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0 0 10px 10px;
}

.deadline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deadline > div {
  /* padding: 40px 20px; */
  flex-basis: calc(50% - 10px);
  text-align: center;
  background: var(--greyWhite);
  max-width: 503px;
}

.deadline > div > p:nth-child(1) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.deadline > div > p:nth-child(2) {
  color: var(--grey);
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.deadline > div > p:nth-child(2) > span {
  color: var(--grey3);
}

.buttonSection {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonSection > button {
  width: 145px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .daysCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 40px;
  }

  .daysCounter > div {
    width: 56px;
    height: 80px;
  }

  .daysCounter > div > div:nth-child(1) {
    height: 75%;
    font-size: 18px;
    line-height: 27px;
  }

  .daysCounter > div > div:nth-child(2) {
    font-size: 10px;
    line-height: 14px;
  }

  .deadline {
    flex-direction: column;
    gap: 16px;
    margin: 22px 0 24px 0;
  }

  .deadline > div {
    padding: 24px;
    flex-basis: calc(100% - 10px);
    width: 90%;
  }

  .deadline > div > p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
  }

  .deadline > div > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 1rem;
    margin: 0;
  }

  .daysCounter {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    margin: 40px;
  }

  .daysCounter > div {
    width: 56px;
    height: 80px;
  }

  .daysCounter > div > div:nth-child(1) {
    height: 75%;
    font-size: 18px;
    line-height: 27px;
  }

  .daysCounter > div > div:nth-child(2) {
    font-size: 10px;
    line-height: 14px;
  }

  .deadline {
    flex-direction: column;
    gap: 16px;
    margin: 22px 0 24px 0;
  }

  .deadline > div {
    padding: 24px;
    flex-basis: calc(100% - 10px);
    width: 90%;
  }

  .deadline > div > p:nth-child(1) {
    font-size: 18px;
    line-height: 27px;
  }

  .deadline > div > p:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }
}
