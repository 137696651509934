.buttonSection {
  width: 120px;
  margin: 80px auto 0 auto;
}

.modalContainer {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.modalContainer > img {
  width: 200px;
  height: 200px;
}

.modalContainer > h4 {
  color: var(--grey);
  text-align: center;
  margin-top: 0.5rem;
  font-family: Archivo;
  font-size: 39px;
  font-style: normal;
  font-weight: 500;
  line-height: 50px;
}

.modalContainer > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin: 0 auto 0.5rem auto;
  width: 45%;
}

.modalButtonSection {
  width: 50%;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .modalContainer > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .modalContainer > p {
    font-size: 14px;
    line-height: 23px;
    width: 50%;
  }

  .modalButtonSection {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .modalContainer {
    gap: 0.5rem;
  }

  .modalContainer > img {
    width: 160px;
    height: 160px;
  }
  .modalContainer > h4 {
    font-size: 25px;
    line-height: 35px;
  }

  .modalContainer > p {
    font-size: 14px;
    line-height: 23px;
    width: 50%;
  }

  .modalButtonSection {
    width: 50%;
  }
}
