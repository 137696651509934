.container {
  padding: 80px;
  position: relative;
  background-color: var(--veryVeryLightPurple);
}

.header {
  text-align: center;
}

.header > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple);
  margin-bottom: 0.5rem;
}

.header > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.header > p:last-child {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.paymentOptions {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 56px;
}

.paymentOptions > div {
  text-align: center;
  position: relative;
  border-radius: 10px;
}

.tag {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.tag > span {
  position: absolute;
  top: 8px;
  left: 0;
  right: 0;
  margin: auto;
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: var(--black);
}

.paymentOptions > div > h3 {
  margin-top: 68px;
  font-family: Archivo;
  font-size: 31px;
  font-weight: 500;
  line-height: 41px;
  text-align: center;
  padding: 12px 0;
  margin-bottom: 16px;
}

.paymentOptions > div > h2 {
  margin: 8px 0;
  font-family: Archivo;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  font-size: 49px;
}

.paymentOptions > div > h2 > span {
  font-size: 18px;
}

.paymentOptions > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  margin-inline: 57px;
  margin-block: 0 54px;
}

.paymentOptions > div:nth-child(1) {
  background-color: var(--purple);
  color: var(--white);
}

.paymentOptions > div:nth-child(1) > h3 {
  border-bottom: 1px solid var(--white);
}

.paymentOptions > div:nth-child(2) > h3 {
  border-bottom: 1px solid var(--grey);
}

.paymentOptions > div:nth-child(2) {
  background-color: var(--white);
  color: var(--grey);
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 1rem;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;

    color: var(--purple);
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .header > p:last-child {
    display: none;
  }

  .paymentOptions {
    gap: 24px;
    margin-top: 24px;
    flex-direction: column;
  }

  .paymentOptions > div {
    max-width: 100%;
    width: 100%;
  }

  .paymentOptions > div > h3 {
    margin-top: 68px;
    font-size: 20px;
    line-height: 30px;
    padding: 12px 0;
  }

  .paymentOptions > div > h2 {
    margin: 8px 0;
    font-size: 31px;
    line-height: 40px;
  }

  .paymentOptions > div > h2 > span {
    font-size: 18px;
  }

  .paymentOptions > div > p {
    font-size: 14px;
    line-height: 23px;
    margin-inline: 16px;
    margin-block: 0 0.5rem;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 1rem;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;

    color: var(--purple);
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .header > p:last-child {
    display: none;
  }

  .paymentOptions {
    gap: 24px;
    margin-top: 24px;
    flex-direction: column;
  }

  .paymentOptions > div {
    max-width: 100%;
    width: 100%;
  }

  .paymentOptions > div > h3 {
    margin-top: 68px;
    font-size: 20px;
    line-height: 30px;
    padding: 12px 0;
  }

  .paymentOptions > div > h2 {
    margin: 8px 0;
    font-size: 31px;
    line-height: 40px;
  }

  .paymentOptions > div > h2 > span {
    font-size: 18px;
  }

  .paymentOptions > div > p {
    font-size: 14px;
    line-height: 23px;
    margin-inline: 16px;
    margin-block: 0 0.5rem;
  }
}
