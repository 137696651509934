.sectionNav {
  margin-top: 217px;
  margin-bottom: 24px;
  border-bottom: 2px solid var(--grey);
  margin-inline: 80px;
}

@media screen and (max-width: 767px) {
  .sectionNav {
    visibility: hidden;
    margin-top: 90px;
    margin-inline: 16px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .sectionNav {
    visibility: hidden;
    margin-top: 90px;
    margin-inline: 16px;
  }
}
