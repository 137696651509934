.container {
  padding-top: 64px;
  position: relative;
  background-color: var(--green);
}

.upperSection {
  padding: 0px 196px;
}

.header {
  text-align: center;
}

.header > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  margin-bottom: 0.5rem;
}

.upperSection > .header > p {
  color: var(--white);
}

.upperSection > .header > h2 {
  color: var(--white);
}

.header > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  margin-bottom: 0.5rem;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
  bottom: 0;
}

.requirementsListSection {
  display: flex;
  align-items: center;
  gap: 11px;
  margin-top: 40px;
}

.requirementsListSection > img {
  width: 400px;
  height: 400px;
}

.requirementsListSection > ul {
  flex: 1;
  list-style-type: none;
}

.requirementsListSection > ul > li {
  margin-left: -40px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.requirementsListSection > ul > li > span:nth-child(1) {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--white);
  border-radius: 50%;
}

.requirementsListSection > ul > li > span:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--white);
}

.bottomSection {
  padding: 64px 80px;
  border-radius: 70px 70px 0px 0px;
  background-color: var(--scheduleRightCtaGreen);
  margin-top: 45px;
}

.bottomSection > .header > p {
  color: var(--green);
}

.bottomSection > .header > h2 {
  color: var(--grey);
}

.admissionSection {
  display: flex;
  align-items: flex-start;
  gap: 208px;
  margin-top: 56px;
}

.admissionSection > div {
  flex-basis: 50%;
}

.admissionSection > div:nth-child(1) > div {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
  height: 128px;
}

.admissionSection > div:nth-child(1) > div > div:nth-child(3) {
  position: absolute;
  height: 50px;
  left: 24px;
  top: 56px;
  border-left: 1.5px solid var(--green);
}

.admissionSection > div:nth-child(1) > div:last-child > div:nth-child(3) {
  display: none;
}

.admissionSection > div:nth-child(1) > div > div:nth-child(1) {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--green);
  flex-shrink: 0;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  color: var(--white);
  border-radius: 50%;
}

.admissionSection > div:nth-child(1) > div > div:nth-child(2) > p:nth-child(1) {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  margin-bottom: 0.5rem;
}

.admissionSection > div:nth-child(1) > div > div:nth-child(2) > p:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.admissionSection > img {
  max-width: 500px;
  max-height: 500px;
  width: 100%;
  height: 100%;
}

.bottomSection > button {
  max-width: 133px;
}

@media screen and (max-width: 767px) {
  .container {
    padding-top: 20px 1rem;
  }

  .upperSection {
    padding: 0px 16px;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .requirementsListSection {
    flex-direction: column;
    gap: 11px;
    margin-top: 24px;
  }

  .requirementsListSection > img {
    width: 343px;
    height: 343px;
  }

  .requirementsListSection > ul > li {
    margin-left: -40px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .requirementsListSection > ul > li > span:nth-child(1) {
    flex-shrink: 0;
  }

  .requirementsListSection > ul > li > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .bottomSection {
    padding: 40px 16px;
    border-radius: 40px 40px 0px 0px;
  }

  .admissionSection {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .admissionSection > div {
    flex-basis: 100%;
  }

  .admissionSection > div:nth-child(1) {
    order: 1;
  }

  .admissionSection > div:nth-child(1) > div {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    position: relative;
    height: 128px;
  }

  .admissionSection > div:nth-child(1) > div > div:nth-child(3) {
    position: absolute;
    height: 70px;
    left: 13px;
    top: 40px;
    border-left: 1.5px solid var(--green);
  }

  .admissionSection > div:nth-child(1) > div:last-child > div:nth-child(3) {
    display: none;
  }

  .admissionSection > div:nth-child(1) > div > div:nth-child(1) {
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 23px;
  }

  .admissionSection
    > div:nth-child(1)
    > div
    > div:nth-child(2)
    > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0.5rem;
  }

  .admissionSection
    > div:nth-child(1)
    > div
    > div:nth-child(2)
    > p:nth-child(2) {
    font-size: 12px;
    line-height: 23px;
  }

  .admissionSection > img {
    max-width: 343px;
    max-height: 343px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding-top: 20px 1rem;
  }

  .upperSection {
    padding: 0px 16px;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span > svg {
    display: none;
  }

  .requirementsListSection {
    flex-direction: column;
    gap: 11px;
    margin-top: 24px;
  }

  .requirementsListSection > img {
    width: 343px;
    height: 343px;
  }

  .requirementsListSection > ul > li {
    margin-left: -40px;
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
  }

  .requirementsListSection > ul > li > span:nth-child(1) {
    flex-shrink: 0;
  }

  .requirementsListSection > ul > li > span:nth-child(2) {
    font-size: 14px;
    line-height: 23px;
  }

  .bottomSection {
    padding: 40px 16px;
    border-radius: 40px 40px 0px 0px;
  }

  .admissionSection {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .admissionSection > div {
    flex-basis: 100%;
  }

  .admissionSection > div:nth-child(1) {
    order: 1;
  }

  .admissionSection > div:nth-child(1) > div {
    display: flex;
    align-items: flex-start;
    gap: 40px;
    position: relative;
    height: 128px;
  }

  .admissionSection > div:nth-child(1) > div > div:nth-child(3) {
    position: absolute;
    height: 70px;
    left: 13px;
    top: 40px;
    border-left: 1.5px solid var(--green);
  }

  .admissionSection > div:nth-child(1) > div:last-child > div:nth-child(3) {
    display: none;
  }

  .admissionSection > div:nth-child(1) > div > div:nth-child(1) {
    width: 24px;
    height: 24px;
    font-size: 12px;
    line-height: 23px;
  }

  .admissionSection
    > div:nth-child(1)
    > div
    > div:nth-child(2)
    > p:nth-child(1) {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 0.5rem;
  }

  .admissionSection
    > div:nth-child(1)
    > div
    > div:nth-child(2)
    > p:nth-child(2) {
    font-size: 12px;
    line-height: 23px;
  }

  .admissionSection > img {
    max-width: 343px;
    max-height: 343px;
  }
}
