.container {
  margin-top: 40px;
}

.container > button {
  max-width: 228px;
  margin-bottom: 40px;
}

.instructions {
  margin-bottom: 40px;
}

.instructions > li {
  list-style: none;
  margin-left: -40px;
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 0.5rem;
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}
