.container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.innerContainer {
  width: 45%;
}

.innerContainer > img {
  width: 668px;
  height: 568px;
  object-fit: cover;
}

.innerContainer > h4 {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 49px;
  font-style: normal;
  font-weight: 500;
  line-height: 61px;
  margin-top: 24px;
}

.innerContainer > p {
  color: var(--grey);
  text-align: center;
  font-family: Archivo;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-top: 1rem;
}

.innerContainer > button {
  width: 195px;
  margin: 24px auto;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    height: auto;
    width: 100%;
    padding: 1rem;
  }

  .innerContainer {
    width: 100%;
  }

  .innerContainer > img {
    width: 343px;
    height: 343px;
  }

  .innerContainer > h4 {
    font-size: 31px;
    line-height: 40px;
    margin-top: 24px;
  }

  .innerContainer > p {
    font-size: 14px;
    line-height: 23px;
  }

  .innerContainer > button {
    width: 178px;
    margin: 24px auto;
  }
}

@media screen and (max-width: 767px) {
  .container {
    height: auto;
    width: 100%;
    padding: 1rem;
    min-height: 100vh;
  }

  .innerContainer {
    width: 100%;
  }

  .innerContainer > img {
    width: 343px;
    height: 343px;
  }

  .innerContainer > h4 {
    font-size: 31px;
    line-height: 40px;
    margin-top: 24px;
  }

  .innerContainer > p {
    font-size: 14px;
    line-height: 23px;
  }

  .innerContainer > button {
    width: 178px;
    margin: 24px auto;
  }
}
