.container {
  padding: 80px;
  background-color: var(--yellow3);
  border-radius: 70px 70px 0px 0px;
}

.upperSection {
  display: flex;
  align-items: flex-start;
  gap: 64px;
  padding-bottom: 56px;
  border-bottom: 1px solid var(--grey);
}

.info {
  flex-basis: 156px;
}

.upperSection > div:not(.info) {
  flex: 1;
}

.logoAndDescription > img {
  width: 53px;
  height: 50px;
}

.logoAndDescription > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  margin: 24px 0;
}

.logoAndDescription > h5 {
  font-family: Archivo;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  text-align: left;
  color: var(--grey);
  margin: 4px 0;
}

.logoAndDescription > a {
  font-family: Archivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: var(--grey);
  display: block;
}

.socialIcons {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 24px;
}

.info > h4 {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 24px;
}

.info > a {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 16px;
  display: block;
  text-decoration: none;
}

.newsLetterSection {
  margin-left: auto;
}

.newsLetterSection > h4 {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 8px;
}

.newsLetterSection > div {
  margin-bottom: 16px;
}

.newsLetterSection > button {
  max-width: 143px;
  margin-bottom: 1rem;
}

.newsLetterSection > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--grey);
}

.lowerSection {
  padding-top: 2rem;
  font-family: Archivo;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 24px;
  color: var(--grey);
}

.lowerSection > a {
  color: var(--grey);
}

.lowerSection > a:nth-child(2) {
  margin-left: auto;
}

@media screen and (max-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 1rem;
    border-radius: 40px 40px 0px 0px;
  }

  .upperSection {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    padding-bottom: 24px;
    flex-wrap: wrap;
  }

  .info {
    flex-basis: 156px;
  }

  .upperSection > div:not(.info) {
    flex-basis: 100%;
  }

  .logoAndDescription > img {
    width: 53px;
    height: 50px;
  }

  .logoAndDescription > p {
    font-size: 14px;
    line-height: 23px;
  }

  .logoAndDescription > h5 {
    font-size: 14px;
    line-height: 23px;
  }

  .logoAndDescription > a {
    font-size: 14px;
    line-height: 23px;
    color: var(--grey);
  }

  .info > h4 {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 16px;
  }

  .info > a {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 8px;
  }

  .newsLetterSection > h4 {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 8px;
  }

  .newsLetterSection > p {
    font-size: 10px;
    line-height: 18px;
  }

  .lowerSection {
    padding-top: 1rem;
    font-size: 12px;
    line-height: 23px;
    flex-wrap: wrap;
  }

  .lowerSection > a:nth-child(2) {
    order: 0;
    margin-left: 0;
  }

  .lowerSection > a {
    flex-basis: 29%;
  }

  .lowerSection > a:nth-child(3) {
    order: 1;
  }

  .lowerSection > a:nth-child(4) {
    order: 2;
  }

  .lowerSection > p {
    order: 3;
  }

  .lowerSection > p:nth-child(1) {
    margin-left: auto;
    flex-basis: 100%;
    text-align: center;
  }
}
