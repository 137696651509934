.container {
  padding: 120px 80px;
  position: relative;
}

.header {
  text-align: center;
}

.header > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--purple);
  margin-bottom: 0.5rem;
}

.header > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 210px;
  height: 47px;
}

.featureSection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 56px;
}

.feature {
  flex-basis: calc(40% - 24px);
  padding: 24px;
  border-radius: 10px;
  background-color: var(--yellow);
}

.feature > img {
  margin-bottom: 8px;
}

.feature > img {
  width: 40px;
  height: 40px;
}

.feature > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 8px;
}

.feature > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
}

.container > p:nth-child(3) {
  font-family: Handlee;
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--black);
  width: 209.79px;
  gap: 0px;
  transform: rotate(-14.84deg);
  margin-top: 103px;
  margin-bottom: 61px;
  margin-inline: auto;
  position: relative;
}

.container > p:nth-child(3) > svg {
  position: absolute;
  right: -0%;
  top: 0;
  bottom: 0;
  margin: auto;
}

.quizSection {
  padding: 2rem;
  width: 535px;
  margin: auto;
  background-color: var(--purple);
  text-align: center;
  border-radius: 20px;
}

.quizSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: center;
  color: var(--white);
  margin-bottom: 24px;
}

.buttonSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.buttonSection > button {
  max-width: 180px;
}

.container > img:nth-child(5) {
  position: absolute;
  top: 150px;
  left: 90px;
}

.container > img:nth-child(6) {
  position: absolute;
  top: 130px;
  right: 130px;
}

.container > img:nth-child(7) {
  position: absolute;
  bottom: 220px;
  right: 210px;
}

.container > img:nth-child(8) {
  position: absolute;
  bottom: 210px;
  left: 150px;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 16px;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-family: Archivo;
    line-height: 18px;
    color: var(--purple);
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    width: 130.75px;
    height: 26.27px;
  }

  .featureSection {
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
  }

  .feature {
    padding: 16px;
    flex-basis: 100%;
  }

  .feature > h4 {
    font-family: Archivo;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .feature > p {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(3) {
    font-family: Handlee;
    font-size: 16px;
    line-height: 22px;
    width: 81.79px;
    margin-bottom: 30px;
  }

  .container > p:nth-child(3) > svg {
    position: absolute;
    right: -50px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > img:nth-child(5),
  .container > img:nth-child(6),
  .container > img:nth-child(7),
  .container > img:nth-child(8) {
    display: none;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
  }

  .header {
    text-align: center;
  }

  .header > p {
    font-family: Archivo;
    line-height: 18px;
    color: var(--purple);
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    width: 130.75px;
    height: 26.27px;
  }

  .featureSection {
    flex-wrap: wrap;
    gap: 12px;
    margin-top: 24px;
  }

  .feature {
    padding: 16px;
    flex-basis: 100%;
  }

  .feature > h4 {
    font-family: Archivo;
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 8px;
  }

  .feature > p {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(3) {
    font-family: Handlee;
    font-size: 16px;
    line-height: 22px;
    width: 81.79px;
    margin-bottom: 30px;
  }

  .container > p:nth-child(3) > svg {
    position: absolute;
    right: -50px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .quizSection {
    padding: 1rem;
    width: 100%;
  }

  .quizSection > p {
    font-size: 14px;
    line-height: 23px;
  }

  .container > img:nth-child(5),
  .container > img:nth-child(6),
  .container > img:nth-child(7),
  .container > img:nth-child(8) {
    display: none;
  }
}
