.container {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--teamCardBorderPurple);
  background-color: var(--white);
  /* width: 100%; */
  max-width: 400px;
}

.container > img {
  width: 100%;
  height: 240px;
  margin: 24px 0;
}

.container > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 8px;
}

.container > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
  margin-bottom: 24px;
}

.container > p:nth-child(4) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
  margin-bottom: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
}

.infoSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.infoSection > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey3);
  padding: 4px 8px;
}

.infoSection > span:nth-child(1) {
  background-color: var(--yellow2);
}

.infoSection > span:nth-child(2) {
  background-color: var(--lightGreen);
}

.container > button {
  max-width: 193px;
}
