.buttonSection {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 40px;
}

.buttonSection > button {
  max-width: 254px;
}

.container > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
}
