.container {
  width: 100%;
}

.progressBar {
  height: 8px;
  background-color: var(--progressBarYellow);
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}

.progressIndicator {
  background: var(--progressBarIndicatorYellow);
  height: 100%;
  border-radius: 50px 0 0 50px;
  transition: all 0.3s ease-in-out;
}

.container > p {
  color: var(--grey);
  font-family: Archivo;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  margin-top: 0.5rem;
}
