.outerContainer {
  background-color: var(--yellow);
  padding-inline: 80px;
  padding-top: calc(5rem + 90px);
  padding-bottom: 80px;
  position: relative;
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}

.textSection {
  flex: 1;
  position: relative;
}

.topSection {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 16px;
  border-radius: 10px;
  background-color: var(--lightGreen);
}

.topSection > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  text-align: right;
  color: var(--grey);
}

.textSection > h1 {
  margin: 0.5rem;
  font-family: Archivo;
  font-size: 61px;
  font-weight: 500;
  line-height: 75px;
  text-align: left;
  color: var(--grey);
  margin-bottom: 1rem 0;
}

.textSection > h1 > span {
  position: relative;
}

.textSection > h1 > span > svg {
  position: absolute;
}

.textSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  width: 574px;
  position: relative;
}

.textSection > p > svg {
  position: absolute;
  right: 206px;
  top: calc(100% + 11px);
}

.textSection > button {
  margin-top: 50px;
  max-width: 245px;
}

.textSection > a {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.5rem;
  margin-bottom: calc(300px - 80px);
  position: relative;
}

.textSection > a > svg:last-child {
  position: absolute;
  left: 400px;
  top: calc(100% + 18px);
}

.imagesSection {
  flex-basis: 50%;
  height: 500px;
}

.imagesSection > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.features {
  padding: 40px 140px;
  border-radius: 70px 70px 10px 10px;
  background-color: var(--purple);
  width: 70%;
  margin: auto;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 40px;
  position: absolute;
  top: 100%;
  height: 232px;
}

.features > div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.features > div > div:nth-child(1) {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff38;
  border-radius: 50%;
}

.features > div > div:nth-child(2) > p:nth-child(1) {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: var(--white);
  margin-bottom: 0.25rem;
}

.features > div > div:nth-child(2) > p:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--white);
}

@media screen and (max-width: 767px) {
  .outerContainer {
    padding-inline: 16px;
    padding-top: calc(2rem + 50px);
    padding-bottom: 200px;
    position: relative;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
  }

  .textSection {
    flex: 1;
    position: relative;
  }

  .topSection {
    display: inline-flex;
    gap: 0.5rem;
    background-color: var(--lightGreen);
  }

  .topSection > span {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > h1 {
    margin-block: 1rem;
    font-size: 36px;
    line-height: 45px;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
    position: relative;
  }

  .textSection > p > svg {
    position: absolute;
    right: 06px;
    top: calc(100% + 11px);
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 245px;
  }

  .textSection > a {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: calc(0px);
  }

  .textSection > a > svg:last-child {
    display: none;
  }

  .imagesSection {
    flex-basis: 328px;
    height: 328px;
    width: 100%;
    display: block;
  }

  .imagesSection > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .features {
    padding: 40px 8px;
    border-radius: 40px 40px 10px 10px;
    width: calc(100% - 2rem);
    bottom: 50%;
    gap: 16px;
    position: absolute;
    top: 100%;
    height: 332px;
  }

  .features > div {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .features > div > div:nth-child(1) {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff38;
    border-radius: 50%;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .outerContainer {
    padding-inline: 16px;
    padding-top: calc(2rem + 50px);
    padding-bottom: 200px;
    position: relative;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 40px;
  }

  .textSection {
    flex: 1;
    position: relative;
  }

  .topSection {
    display: inline-flex;
    gap: 0.5rem;
    background-color: var(--lightGreen);
  }

  .topSection > span {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > h1 {
    margin-block: 1rem;
    font-size: 36px;
    line-height: 45px;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
    position: relative;
  }

  .textSection > p > svg {
    position: absolute;
    right: 06px;
    top: calc(100% + 11px);
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 245px;
  }

  .textSection > a {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: calc(0px);
  }

  .textSection > a > svg:last-child {
    display: none;
  }

  .imagesSection {
    flex-basis: 328px;
    height: 328px;
    width: 100%;
    display: block;
  }

  .imagesSection > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .features {
    padding: 40px 8px;
    border-radius: 40px 40px 10px 10px;
    width: calc(100% - 2rem);
    bottom: 50%;
    gap: 16px;
    position: absolute;
    top: 100%;
    height: 332px;
  }

  .features > div {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .features > div > div:nth-child(1) {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff38;
    border-radius: 50%;
  }
}
