.container {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid var(--teamCardBorderPurple);
  background-color: var(--white);
}

.container > h4 {
  font-family: Archivo;
  margin-bottom: 8px;
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 41px;
  text-align: left;
}

.container > p:nth-child(2) > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: var(--grey);
}

.container > p:nth-child(2) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  display: none;
}

.container > img {
  width: 100%;
  height: 240px;
  margin: 24px 0;
}

.container > p:nth-child(4) {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey3);
  margin-bottom: 24px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 75px;
}

.infoSection {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.infoSection > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #2e2e2e;
  padding: 4px 8px;
}

.infoSection > span:nth-child(1) {
  background-color: #fff6d6;
  border-radius: 2px;
}

.infoSection > span:nth-child(2) {
  background-color: #dff7f3;
  border-radius: 2px;
}

.container > button {
  max-width: 193px;
}

@media screen and (max-width: 767px) {
  .container > h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }

  .container {
    padding: 24px 16px;
  }

  .container > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .container > p:nth-child(2) > span {
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(2) {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .container > img {
    margin: 16px 0;
  }

  .container > p:nth-child(4) {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 16px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 46px;
  }

  .infoSection {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .infoSection > span {
    font-weight: 500;
    line-height: 23px;
    color: var(--grey3);
  }

  .infoSection > span:nth-child(1) {
    background-color: var(--yellow2);
  }

  .infoSection > span:nth-child(2) {
    background-color: var(--lightGreen);
  }

  .container > button {
    max-width: 193px;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container > h4 {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
  }

  .container {
    padding: 24px 16px;
  }

  .container > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .container > p:nth-child(2) > span {
    font-size: 14px;
    line-height: 23px;
  }

  .container > p:nth-child(2) {
    font-family: Archivo;
    font-size: 14px;
    line-height: 23px;
  }

  .container > img {
    margin: 16px 0;
  }

  .container > p:nth-child(4) {
    font-size: 14px;
    line-height: 23px;
    margin-bottom: 16px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    min-height: 46px;
  }

  .infoSection {
    gap: 1rem;
    margin-bottom: 1.5rem;
  }

  .infoSection > span {
    font-weight: 500;
    line-height: 23px;
    color: var(--grey3);
  }

  .infoSection > span:nth-child(1) {
    background-color: var(--yellow2);
  }

  .infoSection > span:nth-child(2) {
    background-color: var(--lightGreen);
  }

  .container > button {
    max-width: 193px;
  }
}
