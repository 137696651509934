.container {
  padding: 120px 80px;
}

.header {
  text-align: center;
}

.header > p {
  font-family: Archivo;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.05em;
  text-align: center;
  color: var(--green);
  margin-bottom: 0.5rem;
}

.header > h2 {
  font-family: Archivo;
  font-size: 49px;
  font-weight: 500;
  line-height: 61px;
  text-align: center;
  color: var(--grey);
  margin-bottom: 0.5rem;
}

.header > h2 > span {
  position: relative;
}

.header > h2 > span > svg {
  position: absolute;
  left: 0;
  width: 243px;
  height: 56px;
}

.informationSection {
  display: flex;
  align-items: stretch;
  gap: 64px;
  margin-top: 55px;
}

.textContainer {
  flex-basis: 50%;
}

.textContainer > div {
  padding: 24px;
  margin-bottom: 12px;
  border-radius: 10px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.textContainer > div:not(.info):hover {
  background-color: var(--bodyBackgroundColor);
}

.textContainer > div > h4 {
  font-family: Archivo;
  font-size: 20px;
  font-weight: 500;
  line-height: 34px;
  text-align: left;
}

.textContainer > div > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

.info {
  background-color: var(--grey);
}

.info > h4 {
  color: var(--white);
  margin-bottom: 0.5rem;
}

.info > p {
  color: var(--white);
}

.imageSection {
  flex-basis: 50%;
}

.imageSection > img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media screen and (max-width: 767px) {
  .container {
    padding: 40px 16px;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    position: absolute;
    right: 0;
    width: 143.85px;
    height: 26.27px;
    display: none;
  }

  .informationSection {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .textContainer {
    flex-basis: 100%;
    order: 1;
  }

  .textContainer > div {
    padding: 1rem 24px;
  }

  .textContainer > div > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .textContainer > div > p {
    font-size: 14px;
    line-height: 23px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }

  .imageSection {
    flex-basis: 100%;
    order: 0;
  }

  .imageSection > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
  }

  .header > p {
    font-size: 10px;
    line-height: 18px;
    margin-bottom: 0.5rem;
  }

  .header > h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .header > h2 > span {
    position: relative;
  }

  .header > h2 > span > svg {
    position: absolute;
    right: 0;
    width: 143.85px;
    height: 26.27px;
    display: none;
  }

  .informationSection {
    flex-direction: column;
    gap: 24px;
    margin-top: 24px;
  }

  .textContainer {
    flex-basis: 100%;
    order: 1;
  }

  .textContainer > div {
    padding: 1rem 24px;
  }

  .textContainer > div > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .textContainer > div > p {
    font-size: 14px;
    line-height: 23px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
  }

  .imageSection {
    flex-basis: 100%;
    order: 0;
  }

  .imageSection > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.animation-fade-in {
  animation-name: fadeIn;
  animation-duration: 500ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
