.outerContainer {
  background-color: var(--yellow);
  padding-inline: 80px;
  padding-top: calc(5rem + 90px);
  padding-bottom: 80px;
}

.container {
  display: flex;
  align-items: flex-start;
  gap: 100px;
}

.textSection {
  flex: 1;
}

.topSection {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.topSection > span {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: right;
  color: var(--green2);
}

.textSection > h1 {
  margin: 0.5rem;
  font-family: Archivo;
  font-size: 61px;
  font-weight: 600;
  line-height: 76px;
  text-align: left;
  color: var(--grey);
}

.textSection > h1 > span {
  position: relative;
}

.textSection > h1 > span > svg {
  position: absolute;
}

.textSection > p {
  font-family: Archivo;
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  text-align: left;
  color: var(--grey);
  width: 574px;
  position: relative;
}

.textSection > p > svg {
  position: absolute;
  right: 206px;
  top: calc(100% + 11px);
}

.textSection > button {
  margin-top: 50px;
  max-width: 245px;
}

.imagesSection {
  flex-basis: 450px;
}

.imagesSection > img {
  width: 668px;
  height: 480px;
}

.brandSection {
  margin-top: 5rem;
}

.brandSection > h4 {
  font-family: Archivo;
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  color: var(--grey);
}

.logoSection {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .outerContainer {
    padding-inline: 1rem;
    padding-top: calc(1.5rem + 50px);
    padding-bottom: 24px;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
  }

  .container > div {
    flex-basis: 50%;
  }

  .topSection {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .topSection > span {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > h1 {
    font-size: 36px;
    line-height: 45px;
    color: var(--grey);
  }

  .textSection > h1 > span {
    position: relative;
  }

  .textSection > h1 > span > svg {
    position: absolute;
    width: 151px;
    height: 34px;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
    position: relative;
  }

  .textSection > p > svg {
    position: absolute;
    right: 45px;
    top: calc(100%);
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 245px;
  }

  .imagesSection > img {
    width: 100%;
  }

  .brandSection {
    margin-top: 1.5rem;
  }

  .brandSection > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .logoSection {
    gap: 16px;
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 204px;
  }
}

@media screen and (max-width: 767px) {
  .outerContainer {
    padding-inline: 1rem;
    padding-top: calc(1.5rem + 50px);
    padding-bottom: 24px;
  }

  .container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 24px;
  }

  .container > div {
    flex-basis: 50%;
  }

  .topSection {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  .topSection > span {
    font-size: 10px;
    line-height: 18px;
  }

  .textSection > h1 {
    font-size: 36px;
    line-height: 45px;
    color: var(--grey);
  }

  .textSection > h1 > span {
    position: relative;
  }

  .textSection > h1 > span > svg {
    position: absolute;
    width: 151px;
    height: 34px;
  }

  .textSection > p {
    font-size: 14px;
    line-height: 23px;
    width: 100%;
    position: relative;
  }

  .textSection > p > svg {
    position: absolute;
    right: 45px;
    top: calc(100%);
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 245px;
  }

  .imagesSection > img {
    width: 100%;
  }

  .brandSection {
    margin-top: 1.5rem;
  }

  .brandSection > h4 {
    font-size: 16px;
    line-height: 25px;
  }

  .logoSection {
    gap: 16px;
  }

  .textSection > button {
    margin-top: 24px;
    max-width: 204px;
  }
}
