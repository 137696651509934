.button {
  border-radius: 5px;
  display: flex;
  padding: 10px 8px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-family: Archivo;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.primary {
  background: var(--purple);
  border: none;
  color: var(--white);
}

.primary:hover {
  background-color: var(--hoverPrimaryBlue);
  color: var(--white);
}

.primary:active {
  background-color: var(--activePrimaryBlue);
  color: var(--white);
}

.secondary {
  border: 1px solid var(--secondaryOutline);
  color: var(--secondaryOutline);
  background: var(--white);
}

.secondary:hover {
  background-color: var(--white);
  color: var(--secondaryHover);
  border: 2px solid var(--hoverPrimaryBlue);
}

.secondary:active {
  border: 1px solid var(--secondaryActive);
  color: var(--secondaryActive);
}

.secondary:disabled {
  border: 2px solid var(--disabled);
  color: var(--disabled);
  cursor: not-allowed;
}

.tertiary {
  color: var(--white-body-text, #fff);
  background: #2ba792;
  border: none;
}

.primary:disabled {
  background-color: var(--disabled);
  cursor: not-allowed;
}

@media screen and (min-width: 767px) and (max-width: 1023px) {
  .button {
    font-size: 14px;
    line-height: 23px;
  }
}

@media screen and (max-width: 767px) {
  .button {
    font-size: 14px;
    line-height: 23px;
  }
}
